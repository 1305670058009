import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/nuestro-equipo',
    name: 'equipo',
    component: () => import('../components/Asesores.vue')
  },
  {
    path: '/asesor-inmuebles',
    name: 'inmuebles',
    component: () => import('../components/Inmuebles.vue')
  },
  {
    path: '/inmueble-detalles',
    name: 'inmueble-detalles',
    component: () => import('../components/Detalles.vue')
  },
  {
    path: '/nuestros-conceptos',
    name: 'Nosotros',
    component: () => import('../components/Nosotros.vue')
  },
  {
    path: '/contactenos',
    name: 'contactenos',
    component: () => import('../components/Contactenos.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
