<template>
  <v-app>
    <toolbar></toolbar>
    <v-main style="padding-bottom: 50px;background-color: #eeeeee;">
      <router-view/>
    </v-main>
    <v-img src="@/assets/administrativo.png"></v-img>
  </v-app>
</template>

<style>
.dff{
  background: #eeeeee;
}
</style>

<script>
import toolbar from './components/toolbar.vue'
import store from './store';
export default {
  name: 'App',

  data: () => ({
    //
  }),
  components:{
    toolbar
  },
  methods:{
    loadVerDolar(){
      fetch('https://s3.amazonaws.com/dolartoday/data.json')
      .then(response => response.json())
      .then(data =>{
        store.commit('setDolar', data.USD.transferencia)
      })
        }
  }
};
</script>